export const API = "api";

export const SPECIAL = "special";
export const Door = "door";
export const Kitchen = "kitchen";
export const Decor = "decor";
export const Stair = "stair";
export const Glass = "glass";
export const Outside = "outside";
export const Partation = "partation";
export const Handmade = "handmade";
export const Stanlissteal = "stanlissteal";

